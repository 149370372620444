.page-company-registration {
  .component-upload {
    overflow: hidden;
    .content-action {
      transition: all 0.2s;
    }

    &:hover {
      .content-action {
        transition: all 0.2s;
        transform: scale(1.1);
      }
    }
  }

  .delete-icon {
    transition: all 0.3s;

    &:hover {
      transition: all 0.3s;
      color: red;
    }
  }
}
