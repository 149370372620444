.box-content {
  display: flex;
  align-items: center;
  width: 200px;
  min-height: 70px !important;
  &:hover{
    cursor: pointer;
    background: rgba(0, 0, 0, .1);
    background-blend-mode: darken;
  }
  &-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  img{
    max-height: 80px;
    min-width: 80px;
  }
}

.content-detail{
 .content-detail-text{
  margin-bottom: 30px;
 }
 .content-detail-img{
  margin: 10px 0 10px 0;
  max-height: 500px;
  object-fit: contain;
 }
}