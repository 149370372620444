.health-care-page{
  .box-img{
    width: 120px;
    height: 100px;
    object-fit: contain;
  }
  .content{
    max-width: 200px;
    img{
      display: none;
    }
  }
}
.detail-article-page{
  .modal-body{
    .title{
      font-size: 28px;
      font-weight: 'bold';
      margin: 10px 0 10px 0;
    }
    .time-read{
      font-size: 12px;
      opacity: 0.7;
    }
    .background-img{
      width: 100%;
      margin: 20px 0 20px 0;
      object-fit: contain;
    }
    .content{
      img{
        max-width: 100%;
      }
    }
  }
}
.ck-content{
  border: 1px solid #c4c4c4 !important;
}